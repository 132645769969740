import React from "react"
import { useSelector } from "react-redux"
import { Grid, Typography } from "@material-ui/core"
import Stack from "@mui/material/Stack"
import logo from "../../../resources/images/Precipart_TxtGray_LeafOrange.png"
import warning from "../../../resources/images/warning.png"
import { gearDepartmentNumbers } from "../../../config"

import useStyles from "./styles/OutputStyles"

function InProcessHeader() {
    const classes = useStyles()
    const numDates = [1, 2, 3, 4, 5]

    const output = useSelector((state) => state.output)

    return (
        <Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    item
                    xs={12}
                >
                    <Grid
                        item
                        container
                        xs={2}
                        justifyContent="flex-start"
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <img
                            alt="Precipart Logo"
                            src={logo}
                            className={classes.Logo}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Stack>
                            {output.ppap && (
                                <Typography
                                    variant="h4"
                                    className={classes.OutputName}
                                    noWrap
                                    align="center"
                                >
                                    <b>Control Plan</b>
                                </Typography>
                            )}
                            <Typography
                                variant="h4"
                                className={classes.OutputName}
                                noWrap
                                align="center"
                            >
                                {`First Piece / In Process / Roving ${
                                    gearDepartmentNumbers.includes(
                                        output.department.trim()
                                    )
                                        ? "(Gear Department)"
                                        : ""
                                }`}
                            </Typography>

                            <Typography
                                variant="h4"
                                className={classes.InspectionReport}
                                noWrap
                                align="center"
                            >
                                Inspection Report
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={2}>
                        {!output.secondVerified ? (
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <img
                                    alt="Warning"
                                    src={warning}
                                    className={classes.Warning}
                                />
                                <Typography
                                    variant="h6"
                                    className={classes.WarningLabel}
                                    align="center"
                                >
                                    First Piece
                                    <br />
                                    Only
                                </Typography>
                            </Grid>
                        ) : (
                            <Typography
                                variant="h4"
                                className={classes.PageNumber}
                                noWrap
                                align="center"
                            ></Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    item
                    xs={12}
                >
                    <Grid item xs={4}>
                        <Typography
                            variant="h6"
                            className={classes.Customer}
                            noWrap
                            align="left"
                        >
                            <strong>Customer: </strong>
                            <u>{output.customer}</u>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="h6"
                            noWrap
                            align="center"
                            className={classes.Dept}
                        >
                            <strong>Department / Machine: </strong>
                            <u>
                                {output.department} / {output.machine}
                            </u>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="h6"
                            noWrap
                            align="right"
                            className={classes.Dept}
                        >
                            <strong>Approved By : </strong>
                            <u>{output.secondVerifiedBy}</u>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                className={classes.OutlineGrid}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="h6"
                    className={classes.SectionPart}
                    noWrap
                    align="left"
                >
                    <strong>Part Number:</strong> <u>{output.partNumber}</u>
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.SectionPart}
                    noWrap
                    align="left"
                >
                    <strong>Revision:</strong> <u>{output.partRevision}</u>
                </Typography>

                <Typography
                    variant="h6"
                    className={classes.SectionPart}
                    noWrap
                    align="left"
                >
                    <strong>Part Name:</strong> <u>{output.partName}</u>
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.SectionPart}
                    noWrap
                    align="left"
                >
                    <strong>Manufacturing Order: </strong>{" "}
                    <u>{output.manufacturingOrder}</u>
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.SectionPart}
                    noWrap
                    align="left"
                >
                    <strong>Operation: </strong>
                    <u>{output.operation}</u>
                </Typography>
            </Grid>
            {gearDepartmentNumbers.includes(output.department.trim()) ? (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item className={classes.GearEvenSpace}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Gear Tester
                                <br />
                                Serial No
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.GearEvenSpaceAlt}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Gear
                                <br />
                                Master
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.GearEvenSpaceAlt}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Mandrel for
                                <br />
                                Gear
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.GearEvenSpaceAlt}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Gage Block
                                <br />
                                Size
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.GearEvenSpaceAlt2}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Number of
                                <br />
                                Teeth
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.GearEvenSpaceAlt2}>
                        <Typography
                            variant="h6"
                            className={classes.SectionGear}
                            noWrap
                            align="left"
                        >
                            <strong>
                                Machine
                                <br />
                                No
                            </strong>
                            <br />
                            <u>Verified</u>
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={5}>
                    <Typography
                        variant="subtitle1"
                        className={classes.JobSetup}
                        noWrap
                        align="left"
                    >
                        First Piece Results/
                        <br />
                        Job Setup By ____________
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        variant="subtitle1"
                        className={
                            gearDepartmentNumbers.includes(
                                output.department.trim()
                            )
                                ? classes.GearBlank
                                : classes.Blank
                        }
                        noWrap
                        align="left"
                    ></Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle1"
                        className={classes.InProcess}
                        noWrap
                        align="left"
                    >
                        In Process Results / Roving Inspections
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item xs={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.Date}
                                noWrap
                                align="left"
                            >
                                Date:
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.DWG}
                                noWrap
                                align="left"
                            >
                                DWG/Routing Characteristics
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={
                                    output.material
                                        ? classes.Material
                                        : classes.NoMaterial
                                }
                                noWrap
                                align="left"
                            >
                                <b>Material: </b>
                                <br />
                                {output.material}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.Stamp}
                                noWrap
                                align="left"
                            >
                                Stamp/Initials:
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.Actual}
                                noWrap
                                align="left"
                            >
                                Actual Readings
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={
                                    output.certification
                                        ? classes.Cert
                                        : classes.NoCert
                                }
                                noWrap
                                align="left"
                            >
                                <b>Certs.No.</b>
                                <br />
                                {output.certification}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant="subtitle1"
                            className={classes.Operator}
                            noWrap
                            align="center"
                        >
                            Operator <br /> Inspection <br /> Sample / <br />{" "}
                            Frequency
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.Method}
                                noWrap
                                align="center"
                            >
                                Mandatory <br /> Method of <br /> Inspection
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.Baseline}
                                noWrap
                                align="center"
                            >
                                (see baseline <br />
                                for all others)
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography
                            variant="subtitle1"
                            className={classes.ToolId}
                            noWrap
                            align="center"
                        >
                            Tool <br /> ID
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        {!output.ppap ? (
                            <Typography
                                variant="subtitle1"
                                className={classes.BluePrint}
                                noWrap
                                align="center"
                            >
                                Blue <br /> Print <br /> Loc
                            </Typography>
                        ) : (
                            <Typography
                                variant="subtitle1"
                                className={classes.ControlMethod}
                                noWrap
                                align="center"
                            >
                                Control <br /> Method
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={6}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    {!output.ppap ? null : (
                        <>
                            <Grid
                                item
                                className={classes.ControlEvenSpaceSmall}
                            >
                                <Typography
                                    variant="subtitle1"
                                    className={classes.ControlMethod}
                                    noWrap
                                    align="center"
                                >
                                    Reaction <br /> Plan
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.ControlEvenSpaceSmall}
                            >
                                <Typography
                                    variant="subtitle1"
                                    className={classes.BluePrint}
                                    noWrap
                                    align="center"
                                >
                                    Blue <br /> Print <br /> Loc
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {numDates.map((item) => (
                        <Grid
                            item
                            className={
                                !output.ppap
                                    ? classes.EvenSpace
                                    : classes.ControlEvenSpace
                            }
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Typography
                                    variant="subtitle1"
                                    className={classes.DateInit}
                                    noWrap
                                    align="left"
                                >
                                    Date:
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={
                                        !output.ppap
                                            ? classes.BlankInit
                                            : classes.ControlBlankInit
                                    }
                                    noWrap
                                    align="left"
                                ></Typography>
                                <Typography
                                    variant="subtitle2"
                                    className={
                                        !output.ppap
                                            ? classes.OpIns
                                            : classes.ControlOpIns
                                    }
                                    noWrap
                                    align="center"
                                >
                                    Actual Readings-
                                    <br />
                                    Operator/Inspector
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InProcessHeader
