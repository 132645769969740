import { makeStyles } from "@material-ui/core/styles"

import "../../../../resources/fonts/fonts.css"
const xxxsmallfont = "7px !important"
const xxsmallfont = "7.5px !important"
const xsmallfont = "8px !important"
const smallfont = "9px !important"
const mediumfont = "10px !important"
const largefont = "11px !important"
const xlargefont = "13px !important"
const xxlargefont = "16px !important"

const maxHeight = "62px"
const halfHeight = "30.5px"

const useStyles = makeStyles((theme) => ({
    Underline: {
        fontSize: largefont,
        textDecoration: "underline"
    },
    OutputContainer: {
        color: "black !important",
        backgroundColor: "white !important"
    },
    MainContainer: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        fontSize: smallfont
    },
    Logo: {
        maxWidth: "150px"
    },
    Warning: {
        maxWidth: "30px"
    },
    WarningLabel: {
        paddingLeft: "5px",
        fontSize: mediumfont
    },
    OutputName: {
        fontSize: xxlargefont,
        fontWeight: "bold",
        width: "100%"
    },
    PageNumber: {
        fontSize: xlargefont
    },
    InspectionReport: {
        fontSize: xxlargefont,
        textDecoration: "underline",
        fontWeight: "bold"
    },
    Customer: {
        fontSize: largefont,
        marginLeft: theme.spacing(2)
    },
    Dept: {
        fontSize: largefont,
        marginRight: theme.spacing(2)
    },
    OutlineGrid: {
        border: "1px solid black"
    },
    SectionPart: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: largefont,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },

    JobSetup: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black"
    },
    Blank: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        marginLeft: "1px",
        paddingBottom: "25px"
    },
    GearBlank: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        marginLeft: "1px",
        paddingBottom: "24px"
    },
    InProcess: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
        paddingBottom: "4px",
        paddingTop: "5px",
        paddingLeft: "1px"
    },
    Date: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "13px"
    },
    DWG: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black"
    },
    Stamp: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "13px",
        paddingLeft: "2px"
    },
    Actual: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        fontWeight: "bold"
    },
    NoCert: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "20px"
    },
    Cert: {
        fontSize: smallfont,
        paddingLeft: "2px",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "6px"
    },
    Material: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "6px",
        paddingLeft: "2px"
    },
    NoMaterial: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "20px",
        paddingLeft: "2px"
    },
    Operator: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "16px"
    },
    Method: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        paddingBottom: "3px"
    },
    Baseline: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black"
    },
    ToolId: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        paddingBottom: "47px"
    },
    BluePrint: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        paddingBottom: "31px"
    },
    ControlMethod: {
        fontSize: smallfont,
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        paddingBottom: "47px"
    },
    DateInit: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderTop: "1px solid black",
        paddingLeft: "2px",
        paddingBottom: "14px",
        width: "100%",
        fontSize: smallfont
    },
    BlankInit: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        fontSize: smallfont,
        paddingBottom: "18px"
    },
    ControlBlankInit: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        fontSize: smallfont,
        paddingBottom: "21px"
    },
    OpIns: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        fontSize: smallfont
    },
    ControlOpIns: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        fontSize: xsmallfont
    },
    EvenSpace: {
        width: "20%"
    },
    ControlEvenSpace: {
        width: "16%"
    },
    ControlEvenSpaceSmall: {
        width: "10%"
    },
    Characteristic: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        paddingLeft: "2px",
        maxHeight: maxHeight,
        minHeight: maxHeight,
        fontSize: smallfont
    },
    CharacteristicKey: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        paddingLeft: "2px",
        maxHeight: maxHeight,
        minHeight: maxHeight,
        fontSize: smallfont,
        fontWeight: "bold"
    },
    CharacteristicBlank: {
        paddingLeft: "2px",
        maxHeight: maxHeight,
        minHeight: maxHeight,
        fontSize: smallfont
    },
    CharacteristicBlankSmall: {
        paddingLeft: "2px",
        maxHeight: maxHeight,
        minHeight: maxHeight,
        overflow: "hidden",
        fontSize: xxxsmallfont
    },
    CharacteristicCell: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
        maxHeight: maxHeight,
        overflow: "hidden"
    },
    CharacteristicCellKey: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
        overflow: "hidden",
        maxHeight: maxHeight,
        fontWeight: "bold"
    },
    CharacteristicCellBlank: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        maxHeight: maxHeight,
        overflow: "hidden"
    },
    CharacteristicEvenSpace: {
        width: "20%",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        maxHeight: maxHeight,
        overflow: "hidden"
    },
    ControlCharacteristicEvenSpaceSmall: {
        width: "10%",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        maxHeight: maxHeight,
        overflow: "hidden"
    },
    ControlCharacteristicEvenSpace: {
        width: "16%",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        maxHeight: maxHeight,
        overflow: "hidden"
    },
    CharacteristicEntryNum1: {
        fontSize: xlargefont,
        borderRight: "1px solid black",
        minHeight: halfHeight,
        paddingRight: "1px"
    },
    CharacteristicEntry1: {
        fontSize: smallfont,
        borderBottom: "1px solid black"
    },
    CharacteristicEntryNum2: {
        fontSize: xlargefont,
        minHeight: halfHeight,
        borderRight: "1px solid black",
        paddingRight: "1px"
    },
    CharacteristicEntry2: {
        fontSize: mediumfont,
        borderBottom: "1px solid black"
    },
    FooterBox: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black"
    },
    FooterContent: {
        fontSize: xxsmallfont
    },
    FooterContentRight: {
        fontSize: xsmallfont,
        marginRight: theme.spacing(2)
    },
    BaseLineFooter: {
        paddingRight: "5px"
    },
    Padding: {
        width: "21%",
        paddingRight: "5px"
    },
    PaddingLarge: {
        width: "30%"
    },
    BottomPush: {
        bottom: "0"
    },
    OperatorInit: {
        fontSize: mediumfont,
        width: "25%",
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black"
    },
    LargeBlank: {
        width: "25%",
        borderRight: "1px solid black",
        borderBottom: "1px solid black"
    },
    ControlLargeBlank: {
        width: "35%",
        borderRight: "1px solid black",
        borderBottom: "1px solid black"
    },
    ControlSmallBlank: {
        width: "8%",
        borderRight: "1px solid black",
        borderBottom: "1px solid black"
    },
    SmallBlank: {
        width: "10%",
        borderRight: "1px solid black",
        borderBottom: "1px solid black"
    },
    Initials: {
        fontSize: mediumfont
    },
    OpInstructions: {
        fontSize: mediumfont
    },
    PQFandDate: {
        fontSize: mediumfont
    },
    PageBreak: {
        fontSize: mediumfont,
        pageBreakAfter: "always"
    },
    PageBreakNo: {
        fontSize: mediumfont
    },
    GearEvenSpace: {
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        width: "17%"
    },
    GearEvenSpaceAlt: {
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        width: "17%"
    },
    GearEvenSpaceAlt2: {
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        width: "16%"
    },
    SectionGear: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: smallfont
    },
    KeyIcon: {
        fontSize: largefont,
        marginRight: "2px"
    }
}))

export default useStyles
